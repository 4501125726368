import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import { Col, Collapse, ListGroup, ListGroupItem } from "reactstrap";
import { useCommonData } from "../../../../../providers/commonDataProvider";

import CustomCheckbox from "../../../../CustomCheckbox";

const QuoteItemModalManagementRoles = ({
  selectedManagementRoles,
  setSelectedManagementRoles,
}) => {
  const [commonDataContext] = useCommonData();
  const [openSection, setOpenSection] = useState();

  const onItemClick = (managementRole) => {
    setSelectedManagementRoles({
      ...selectedManagementRoles,
      [managementRole.id]: !selectedManagementRoles[managementRole.id],
    });
  };

  const onSelectAllClick = () => {
    const allChecked =
      Object.keys(selectedManagementRoles).filter(
        (sc) => selectedManagementRoles[sc]
      ).length === commonDataContext.quoteItemManagementRoles.length;
    const newSelectedManagementRoles =
      commonDataContext.quoteItemManagementRoles.reduce(
        (p, c) => ({
          ...p,
          [c.id]: !allChecked,
        }),
        {}
      );
    setSelectedManagementRoles({
      ...newSelectedManagementRoles,
    });
  };

  return commonDataContext.quoteItemManagementRoles.length ? (
    <Col className="col-12 mt-3">
      <ListGroup>
        <ListGroupItem
          className={`d-flex justify-content-between align-items-center bg-lighter cursor-pointer border-bottom-${
            openSection ? 0 : 1
          }`}
          onClick={() => setOpenSection(!openSection)}
        >
          <div>
            <span>Management Roles</span>
          </div>
          <FontAwesomeIcon
            icon={openSection ? faChevronDown : faChevronUp}
            className="text-muted"
          />
        </ListGroupItem>
        <Collapse isOpen={openSection}>
          {commonDataContext.quoteItemManagementRoles.length > 1 && (
            <ListGroupItem
              className="d-flex justify-content-between align-items-center cursor-pointer"
              onClick={() => onSelectAllClick()}
            >
              <div className="flex-shrink-0">Select all</div>
              <CustomCheckbox
                checked={
                  Object.keys(selectedManagementRoles).filter(
                    (c) => selectedManagementRoles[c]
                  ).length === commonDataContext.quoteItemManagementRoles.length
                }
              />
            </ListGroupItem>
          )}
          {commonDataContext.quoteItemManagementRoles.map((managementRole) => (
            <ListGroupItem
              key={managementRole.id}
              className="d-flex justify-content-between align-items-center cursor-pointer"
              onClick={() => onItemClick(managementRole)}
            >
              <div className="flex-shrink-0">{`${managementRole.name}`}</div>
              <CustomCheckbox
                checked={selectedManagementRoles[managementRole.id]}
              />
            </ListGroupItem>
          ))}
        </Collapse>
      </ListGroup>
    </Col>
  ) : null;
};

export default QuoteItemModalManagementRoles;
