import React, { useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { reportsApi } from "../../../services/reportsServices";
import Loader from "../../../components/Loader";
import InformationModal from "../../../components/InformationModal";
import { utils } from "../../../utils/utils";
import PeriodTypeSelector from "../../../components/PeriodTypeSelector";

const columns = {
  "Job Number": {
    type: "string",
  },
  Customer: {
    type: "string",
  },
  Description: {
    type: "string",
  },
  "Est. Ship Date": {
    type: "string",
  },
  "Supervisor - Regular": {
    type: "string",
  },
  "Supervisor - Overtime": {
    type: "string",
  },
  "Foreman - Regular": {
    type: "string",
  },
  "Foreman - Overtime": {
    type: "string",
  },
  "Welder - Regular": {
    type: "string",
  },
  "Welder - Overtime": {
    type: "string",
  },
  "Welder Appr.- Regular": {
    type: "string",
  },
  "Weldern Appr. - Overtime": {
    type: "string",
  },
  "Millwright Appr.- Regular": {
    type: "string",
  },
  "Millwright Appr. - Overtime": {
    type: "string",
  },
  "Safety Attendant - Regular": {
    type: "string",
  },
  "31 - Engineering": {
    type: "string",
  },
  "Remaining Man Hours": {
    type: "string",
  },
};

const report = {
  dataSource: {
    data: [columns],
  },
  slice: {
    rows: [
      {
        uniqueName: "Job Number",
      },
      {
        uniqueName: "Customer",
      },
      {
        uniqueName: "Description",
      },
      {
        uniqueName: "Est. Ship Date",
      },
      {
        uniqueName: "Supervisor - Regular",
      },
      {
        uniqueName: "Supervisor - Overtime",
      },
      {
        uniqueName: "Foreman - Regular",
      },
      {
        uniqueName: "Foreman - Overtime",
      },
      {
        uniqueName: "Welder - Regular",
      },
      {
        uniqueName: "Welder - Overtime",
      },
      {
        uniqueName: "Welder Appr.- Regular",
      },
      {
        uniqueName: "Weldern Appr. - Overtime",
      },
      {
        uniqueName: "Millwright Appr.- Regular",
      },
      {
        uniqueName: "Millwright Appr. - Overtime",
      },
      {
        uniqueName: "Safety Attendant - Regular",
      },
      {
        uniqueName: "31 - Engineering",
      },
      {
        uniqueName: "Remaining Man Hours",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Man Hour Report",
    },
  },
};

const ManHourReport = () => {
  const pivot = useRef(null);
  const currentYear = moment().year();

  const [loading, setLoading] = useState();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        let result = await reportsApi.manHourSummary();
        if (pivot) {
          pivot.current.flexmonster.updateData({
            data: [columns, ...result.data],
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, [pivot, currentYear]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <h3 className="mb-0">Man Hour Report</h3>
              <div className="d-flex align-items-center">
                {loading ? (
                  <div className="min-width-50">
                    <Loader size="sm" className="mr-3" />
                  </div>
                ) : null}
                <div className="d-none">
                  <PeriodTypeSelector />
                </div>
              </div>
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  key={currentYear}
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utils.customizeToolbarReport(
                      toolbar,
                      pivot,
                      "man_hour_report"
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default ManHourReport;
