import React, { useEffect, useState } from "react";

import InformationModal from "../../../InformationModal";
import { Input, Button, ListGroup, ListGroupItem } from "reactstrap";
import QuoteItemEditOptionsModal from "./QuoteItemEditOptionsModal";
import { useQuoteItem, ACTIONS } from "../../../../providers/quoteItemProvider";
import { utils } from "../../../../utils/utils";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";

const QuoteItemManagementRoles = ({
  quoteItemManagementRoles,
  setQuoteItemManagementRoles,
}) => {
  const [quoteItemContext, setQuoteItemContext] = useQuoteItem();
  const [jobDetails] = useJobDetails();

  const isJobLocked = jobDetails.job.lockDate;

  const [editOptionsModal, setEditOptionsModal] = useState();
  const [totalHours, setTotalHours] = useState(0);

  useEffect(() => {
    const quoteItemManagementRoles =
      quoteItemContext.quoteItem.quoteItemManagementRoles.reduce((p, c) => {
        p[c.id] = c;
        return p;
      }, {});
    setQuoteItemManagementRoles(quoteItemManagementRoles);
  }, [quoteItemContext.quoteItem, setQuoteItemManagementRoles]);

  useEffect(() => {
    const totalHours = Object.values(quoteItemManagementRoles).reduce(
      (p, c) => p + parseFloat(c.hours || 0),
      0
    );
    setTotalHours(totalHours);
  }, [quoteItemManagementRoles]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const onFieldChange = (managementRoleId, field, value) =>
    setQuoteItemManagementRoles({
      ...quoteItemManagementRoles,
      [managementRoleId]: {
        ...quoteItemManagementRoles[managementRoleId],
        [field]: value || 0,
      },
    });

  return (
    <ListGroup>
      <ListGroupItem className="border-radius-0 d-flex justify-content-between align-items-center bg-lighter">
        <small className="font-weight-bold text-muted">Management Roles</small>
        {isJobLocked ? null : (
          <div className="d-flex">
            <Button
              className="rounded"
              size="sm"
              onClick={() => setEditOptionsModal(true)}
            >
              Edit Options
            </Button>
          </div>
        )}
      </ListGroupItem>
      {quoteItemContext.quoteItem.quoteItemManagementRoles
        .sort((x, y) =>
          x.managementRole.code < y.managementRole.code ? -1 : 1
        )
        .map((quoteItemManagementRole) => {
          const hours =
            quoteItemManagementRoles[quoteItemManagementRole.id]?.hours || "";
          return (
            <ListGroupItem
              key={quoteItemManagementRole.id}
              className="border-radius-0 d-flex justify-content-between align-items-center py-0"
            >
              <span>{`${quoteItemManagementRole.managementRole.name}`}</span>
              <div className="width-150">
                <Input
                  className="border text-right m-1"
                  placeholder="Enter hours..."
                  type="number"
                  readOnly={isJobLocked}
                  min="0"
                  max="99999"
                  name="hours"
                  value={hours}
                  onChange={(e) =>
                    onFieldChange(
                      quoteItemManagementRole.id,
                      "hours",
                      e.currentTarget.value
                    )
                  }
                />
              </div>
            </ListGroupItem>
          );
        })}
      <ListGroupItem className="border-radius-0 d-flex flex-column py-0">
        <div className="d-flex justify-content-between align-items-center">
          <span className="font-weight-bold">Total Hours</span>
          <div className="width-150">
            <div readOnly className="form-control border text-right m-1">
              {utils.formatHour(totalHours)}
            </div>
          </div>
        </div>
      </ListGroupItem>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : editOptionsModal ? (
        <QuoteItemEditOptionsModal
          title="Management Roles"
          field="quoteItemManagementRoles"
          idField="managementRoleId"
          quoteItem={quoteItemContext.quoteItem}
          onSubmit={() => {
            setEditOptionsModal(false);
            setQuoteItemContext({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => setEditOptionsModal(false)}
        />
      ) : null}
    </ListGroup>
  );
};

export default QuoteItemManagementRoles;
