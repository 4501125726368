import React, { useEffect, useState } from "react";
import { Input, Table } from "reactstrap";
import { utils } from "../../../../../utils/utils";
import {
  useEvaOverride,
  ACTIONS as EVA_OVERRIDE_ACTIONS,
} from "../../../../../providers/evaOverrideProvider";
import { useJobDetails } from "../../../../../providers/jobDetailsProvider";
import { useCommonData } from "../../../../../providers/commonDataProvider";
import { useQuoteItems } from "../../../../../providers/quoteItemsProvider";

const EstimatedActualShippingHandlings = () => {
  const [evaOverrideContext, setEvaOverrideContext] = useEvaOverride();
  const [commonDataContext] = useCommonData();
  const [jobDetails] = useJobDetails();
  const [quoteItemsContext] = useQuoteItems();
  const [shippingHandlingOptions, setShippingHandlingOptions] = useState([]);

  const [estimatedTotalCost, setEstimatedTotalCost] = useState(0);
  const [actualTotalCost, setActualTotalCost] = useState(0);
  const [differenceTotal, setDifferenceTotal] = useState(0);

  useEffect(() => {
    const quoteItemShippingHandlings = utils.reduceQuoteItemShippingHandlings(
      quoteItemsContext.quoteItems
    );

    const shippingHandlingOptions =
      commonDataContext.quoteItemShippingHandlings.map(
        (quoteItemShippingHandling) => {
          const actualItem = jobDetails.job.teklaMaterialData.find(
            (item) =>
              item.shippingHandlingOptionId === quoteItemShippingHandling.id
          );
          const actualCost = actualItem?.cost || 0;

          const estimatedItem = quoteItemShippingHandlings.find(
            (item) =>
              item.shippingHandlingOptionId === quoteItemShippingHandling.id
          );

          const estimatedItemCost = estimatedItem?.cost || 0;

          return {
            ...quoteItemShippingHandling,
            actualCost: actualCost,
            estimatedCost: estimatedItemCost,
          };
        }
      );

    if (!evaOverrideContext.editEnabled) {
      jobDetails.job.shippingHandlingOptions = shippingHandlingOptions;
    }
    setShippingHandlingOptions(shippingHandlingOptions);
  }, [
    commonDataContext.quoteItemShippingHandlings,
    quoteItemsContext,
    jobDetails.job,
    evaOverrideContext.editEnabled,
  ]);

  useEffect(() => {
    const { totalCost, totalActual } = shippingHandlingOptions.reduce(
      (acc, shippingHandlingOption) => {
        const actualCostOverride =
          evaOverrideContext.shippingHandlingOptions[shippingHandlingOption.id];

        return {
          totalCost: acc.totalCost + shippingHandlingOption.estimatedCost,
          totalActual:
            acc.totalActual +
            (actualCostOverride || shippingHandlingOption.actualCost),
        };
      },
      { totalCost: 0, totalActual: 0 }
    );

    const differenceTotal = totalCost - totalActual;

    if (!evaOverrideContext.editEnabled) {
      jobDetails.job.shippingHandlingOptionsTotalActual = totalActual;
      jobDetails.job.shippingHandlingOptionsTotalCost = totalCost;
      jobDetails.job.shippingHandlingOptionsDifferenceTotal = differenceTotal;
      jobDetails.job.shippingHandlingOptionsOverride =
        evaOverrideContext.shippingHandlingOptions;
    }
    setActualTotalCost(totalActual);
    setEstimatedTotalCost(totalCost);
    setDifferenceTotal(differenceTotal);
  }, [
    shippingHandlingOptions,
    evaOverrideContext.shippingHandlingOptions,
    evaOverrideContext.editEnabled,
    jobDetails.job,
  ]);

  return (
    <Table className="col-12 px-0 border mt-4 rounded mb-0">
      <tbody>
        {shippingHandlingOptions.map((shippingHandlingOption) => {
          const actualCostOverride =
            evaOverrideContext.shippingHandlingOptions[
              shippingHandlingOption.id
            ];
          const actualCost =
            actualCostOverride || shippingHandlingOption.actualCost;

          return (
            <tr key={shippingHandlingOption.id}>
              <td className="py-0 text-truncate" width={"45%"}>
                {shippingHandlingOption.name}
              </td>
              <td className="py-0" width={"15%"}>
                <div readOnly className="form-control border text-right m-1">
                  {utils.formatCurrency(shippingHandlingOption.estimatedCost)}
                </div>
              </td>
              <td className="py-0" width={"15%"}>
                <Input
                  className="form-control border text-right m-1 hide-arrows"
                  type={evaOverrideContext.editEnabled ? "number" : "text"}
                  step="0.1"
                  value={
                    evaOverrideContext.editEnabled
                      ? parseFloat(actualCost)
                      : utils.formatCurrency(actualCost)
                  }
                  readOnly={!evaOverrideContext.editEnabled}
                  onChange={(evt) =>
                    setEvaOverrideContext({
                      action: EVA_OVERRIDE_ACTIONS.UPDATE_ITEM,
                      payload: {
                        id: shippingHandlingOption.id,
                        key: "shippingHandlingOptions",
                        value: evt.currentTarget.value,
                      },
                    })
                  }
                />
              </td>
              <td className="py-0" width={"15%"}>
                <div
                  readOnly
                  className={`${
                    shippingHandlingOption.estimatedCost - actualCost < 0
                      ? "text-danger"
                      : "text-success"
                  } form-control border text-right m-1 font-weight-bold`}
                >
                  {utils.formatCurrency(
                    shippingHandlingOption.estimatedCost - actualCost
                  )}
                </div>
              </td>
            </tr>
          );
        })}
        <tr className="text-muted bg-lighter">
          <th width={"45%"} className="align-middle">
            Total
          </th>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(estimatedTotalCost)}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(actualTotalCost)}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className={`form-control border text-right m-1 font-weight-bold ${
                differenceTotal < 0 ? "text-danger" : "text-success"
              }`}
            >
              {utils.formatCurrency(differenceTotal)}
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default EstimatedActualShippingHandlings;
