import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import { Col, Collapse, ListGroup, ListGroupItem } from "reactstrap";
import { useCommonData } from "../../../../../providers/commonDataProvider";
import { utils } from "../../../../../utils/utils";

import CustomCheckbox from "../../../../CustomCheckbox";

const QuoteItemModalLaborCategories = ({
  selectedCategories,
  setSelectedCategories,
}) => {
  const [commonDataContext] = useCommonData();
  const [openSection, setOpenSection] = useState();

  const onItemClick = (laborCategory) => {
    setSelectedCategories({
      ...selectedCategories,
      [laborCategory.id]: !selectedCategories[laborCategory.id],
    });
  };

  const onSelectAllClick = () => {
    const allChecked =
      Object.keys(selectedCategories).filter((sc) => selectedCategories[sc])
        .length === commonDataContext.quoteItemLaborCategories.length;
    const newSelectedCategories =
      commonDataContext.quoteItemLaborCategories.reduce(
        (p, c) => ({
          ...p,
          [c.id]: !allChecked,
        }),
        {}
      );
    setSelectedCategories({
      ...newSelectedCategories,
    });
  };

  return commonDataContext.quoteItemLaborCategories.length ? (
    <Col className="col-12 mt-3">
      <ListGroup>
        <ListGroupItem
          className={`d-flex justify-content-between align-items-center bg-lighter cursor-pointer border-bottom-${
            openSection ? 0 : 1
          }`}
          onClick={() => setOpenSection(!openSection)}
        >
          <div>
            <span>Labor Categories</span>
          </div>
          <FontAwesomeIcon
            icon={openSection ? faChevronDown : faChevronUp}
            className="text-muted"
          />
        </ListGroupItem>
        <Collapse isOpen={openSection}>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center cursor-pointer"
            onClick={() => onSelectAllClick()}
          >
            <div className="flex-shrink-0">Select all</div>
            <CustomCheckbox
              checked={
                Object.keys(selectedCategories).filter(
                  (c) => selectedCategories[c]
                ).length === commonDataContext.quoteItemLaborCategories.length
              }
            />
          </ListGroupItem>
          {commonDataContext.quoteItemLaborCategories
            .filter(
              (laborCategory) =>
                !utils.laborCategoriesExcluded.includes(+laborCategory.code)
            )
            .map((laborCategory) => (
              <ListGroupItem
                key={laborCategory.id}
                className="d-flex justify-content-between align-items-center cursor-pointer"
                onClick={() => onItemClick(laborCategory)}
              >
                <div className="flex-shrink-0">{`${laborCategory.name}`}</div>
                <CustomCheckbox
                  checked={selectedCategories[laborCategory.id]}
                />
              </ListGroupItem>
            ))}
        </Collapse>
      </ListGroup>
    </Col>
  ) : null;
};

export default QuoteItemModalLaborCategories;
