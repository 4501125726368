import React, { useEffect, useState } from "react";
import { Input, Table } from "reactstrap";
import { useCommonData } from "../../../../../providers/commonDataProvider";
import { utils } from "../../../../../utils/utils";
import {
  useEvaOverride,
  ACTIONS as EVA_OVERRIDE_ACTIONS,
} from "../../../../../providers/evaOverrideProvider";
import { useJobDetails } from "../../../../../providers/jobDetailsProvider";
import { useQuoteItems } from "../../../../../providers/quoteItemsProvider";

const SUBCONTRACTOR_MISCELLANEOUS_ID = 5;

const EstimatedActualSubContractorsSection = () => {
  const [evaOverrideContext, setEvaOverrideContext] = useEvaOverride();
  const [commonDataContext] = useCommonData();
  const [jobDetails] = useJobDetails();
  const [quoteItemsContext] = useQuoteItems();
  const [subcontractorRoles, setSubcontractorRoles] = useState([]);

  const [estimatedTotalCost, setEstimatedTotalCost] = useState(0);
  const [actualTotalCost, setActualTotalCost] = useState(0);
  const [differenceTotal, setDifferenceTotal] = useState(0);

  useEffect(() => {
    const quoteItemSubcontractorRoles = utils.reduceQuoteItemSubcontractorRoles(
      quoteItemsContext.quoteItems
    );

    // idenify the subcontractorRole with replacementRole field to exclude them from the screen
    const idsToExclude = commonDataContext.quoteItemSubcontractorRoles
      .filter((subcontractorRole) => subcontractorRole.replacementRole)
      .map((subcontractorRole) => subcontractorRole.id);

    const subcontractorRoles =
      commonDataContext.quoteItemSubcontractorRoles.map(
        (quoteItemSubcontractorRole) => {
          // idenify the subcontractorRole ids to include as part of the sum (actualCost) for each subcontractorRole
          const idsToInclude = commonDataContext.quoteItemSubcontractorRoles
            .filter(
              (subcontractorRole) =>
                subcontractorRole.replacementRole &&
                subcontractorRole.replacementRole.id ===
                  quoteItemSubcontractorRole.id
            )
            .map((subcontractorRole) => subcontractorRole.id);
          idsToInclude.push(quoteItemSubcontractorRole.id);

          // get the actualCost value for each subcontractorRole
          const actualCost = jobDetails.job.teklaMaterialData
            .filter((teklaMaterial) =>
              idsToInclude.includes(teklaMaterial.subcontractorRoleId)
            )
            .reduce((p, c) => p + c?.cost, 0);

          const estimatedItem = quoteItemSubcontractorRoles.find(
            (item) => item.subcontractorRoleId === quoteItemSubcontractorRole.id
          );

          const estimatedCost = estimatedItem?.cost || 0;

          return {
            ...quoteItemSubcontractorRole,
            actualCost,
            estimatedCost,
          };
        }
      );

    const subcontractorsRoles = subcontractorRoles.filter(
      (subcontractorRole) =>
        !idsToExclude.includes(subcontractorRole.id) &&
        subcontractorRole.id !== SUBCONTRACTOR_MISCELLANEOUS_ID
    );

    if (!evaOverrideContext.editEnabled) {
      jobDetails.job.subcontractorRoles = subcontractorsRoles;
    }
    setSubcontractorRoles(subcontractorsRoles);
  }, [
    commonDataContext.quoteItemSubcontractorRoles,
    quoteItemsContext,
    jobDetails.job,
    evaOverrideContext.editEnabled,
  ]);

  useEffect(() => {
    const { totalCost, totalActual } = subcontractorRoles.reduce(
      (acc, subcontractorRole) => {
        const actualCostOverride =
          evaOverrideContext.subcontractorRoles[subcontractorRole.id];

        return {
          totalCost: acc.totalCost + subcontractorRole.estimatedCost,
          totalActual:
            acc.totalActual +
            (actualCostOverride || subcontractorRole.actualCost),
        };
      },
      { totalCost: 0, totalActual: 0 }
    );

    const differenceTotal = totalCost - totalActual;

    if (!evaOverrideContext.editEnabled) {
      jobDetails.job.subcontractorRolesTotalActual = totalActual;
      jobDetails.job.subcontractorRolesTotalCost = totalCost;
      jobDetails.job.subcontractorRolesDifferenceTotal = differenceTotal;
      jobDetails.job.subcontractorRolesOverride =
        evaOverrideContext.subcontractorRoles;
    }
    setActualTotalCost(totalActual);
    setEstimatedTotalCost(totalCost);
    setDifferenceTotal(differenceTotal);
  }, [
    subcontractorRoles,
    evaOverrideContext.subcontractorRoles,
    evaOverrideContext.editEnabled,
    jobDetails.job,
  ]);

  return (
    <Table className="col-12 mt-4 px-0 border rounded mb-0">
      <tbody>
        {subcontractorRoles.map((subcontractorRole, index) => {
          const actualCostOverride =
            evaOverrideContext.subcontractorRoles[subcontractorRole.id];
          const actualCost = actualCostOverride || subcontractorRole.actualCost;

          return (
            <tr key={"quoteItemSubcontractorRole" + index}>
              <td className="py-0 text-truncate" width={"45%"}>
                {subcontractorRole.name}
              </td>
              <td className="py-0" width={"15%"}>
                <div readOnly className="form-control border text-right m-1">
                  {utils.formatCurrency(subcontractorRole.estimatedCost)}
                </div>
              </td>
              <td className="py-0" width={"15%"}>
                <Input
                  className="form-control border text-right m-1 hide-arrows"
                  type={evaOverrideContext.editEnabled ? "number" : "text"}
                  step="0.1"
                  value={
                    evaOverrideContext.editEnabled
                      ? parseFloat(actualCost)
                      : utils.formatCurrency(actualCost)
                  }
                  readOnly={!evaOverrideContext.editEnabled}
                  onChange={(evt) => {
                    setEvaOverrideContext({
                      action: EVA_OVERRIDE_ACTIONS.UPDATE_ITEM,
                      payload: {
                        id: subcontractorRole.id,
                        key: "subcontractorRoles",
                        value: evt.currentTarget.value,
                      },
                    });
                  }}
                />
              </td>
              <td className="py-0" width={"15%"}>
                <div
                  readOnly
                  className={`${
                    subcontractorRole.estimatedCost - actualCost < 0
                      ? "text-danger"
                      : "text-success"
                  } form-control border text-right m-1 font-weight-bold`}
                >
                  {utils.formatCurrency(
                    subcontractorRole.estimatedCost - actualCost
                  )}
                </div>
              </td>
            </tr>
          );
        })}
        <tr className="text-muted bg-lighter">
          <th width={"45%"} className="align-middle">
            Total
          </th>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(estimatedTotalCost)}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(actualTotalCost)}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className={`form-control border text-right m-1 font-weight-bold ${
                differenceTotal < 0 ? "text-danger" : "text-success"
              }`}
            >
              {utils.formatCurrency(differenceTotal)}
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default EstimatedActualSubContractorsSection;
