import React, { useEffect, useState } from "react";

import InformationModal from "../../../InformationModal";
import { Input, Button, ListGroup, ListGroupItem } from "reactstrap";
import QuoteItemEditOptionsModal from "./QuoteItemEditOptionsModal";
import { useQuoteItem, ACTIONS } from "../../../../providers/quoteItemProvider";
import { utils } from "../../../../utils/utils";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";

const QuoteItemLaborCategories = ({
  quoteItemLaborCategories,
  setQuoteItemLaborCategories,
}) => {
  const [quoteItemContext, setQuoteItemContext] = useQuoteItem();
  const [jobDetails] = useJobDetails();

  const isJobLocked = jobDetails.job.lockDate;

  const [editOptionsModal, setEditOptionsModal] = useState();
  const [totalHours, setTotalHours] = useState(0);

  useEffect(() => {
    const quoteItemLaborCategories =
      quoteItemContext.quoteItem.quoteItemLaborCategories.reduce((p, c) => {
        p[c.id] = c;
        return p;
      }, {});
    setQuoteItemLaborCategories(quoteItemLaborCategories);
  }, [quoteItemContext.quoteItem, setQuoteItemLaborCategories]);

  useEffect(() => {
    const totalHours = Object.values(quoteItemLaborCategories).reduce(
      (p, c) => p + parseFloat(c.hours || 0),
      0
    );
    setTotalHours(totalHours);
  }, [quoteItemLaborCategories]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const onFieldChange = (laborCategoryId, field, value) =>
    setQuoteItemLaborCategories({
      ...quoteItemLaborCategories,
      [laborCategoryId]: {
        ...quoteItemLaborCategories[laborCategoryId],
        [field]: value || 0,
      },
    });

  return (
    <>
      <ListGroup>
        <ListGroupItem className="border-radius-0 d-flex justify-content-between align-items-center bg-lighter">
          <small className="font-weight-bold text-muted">
            Labor Categories
          </small>
          {isJobLocked ? null : (
            <div className="d-flex">
              <Button
                className="rounded"
                size="sm"
                onClick={() => setEditOptionsModal(true)}
              >
                Edit Options
              </Button>
            </div>
          )}
        </ListGroupItem>
        {quoteItemContext.quoteItem.quoteItemLaborCategories
          .sort((x, y) =>
            parseInt(x.laborCategory.code) < parseInt(y.laborCategory.code)
              ? -1
              : 1
          )
          .map((laborCategory) => {
            const hours =
              quoteItemLaborCategories[laborCategory.id]?.hours || "";
            return (
              <ListGroupItem
                key={laborCategory.id}
                className="border-radius-0 d-flex justify-content-between align-items-center py-0"
              >
                <span>{`${laborCategory.laborCategory.name}`}</span>
                <div className="width-150">
                  <Input
                    className="border text-right m-1"
                    placeholder="Enter hours..."
                    type="number"
                    readOnly={isJobLocked}
                    disabled={isJobLocked}
                    min="0"
                    max="99999"
                    name="hours"
                    value={hours}
                    onChange={(e) =>
                      onFieldChange(
                        laborCategory.id,
                        "hours",
                        e.currentTarget.value
                      )
                    }
                  />
                </div>
              </ListGroupItem>
            );
          })}
        <ListGroupItem className="border-radius-0 d-flex flex-column py-0">
          <div className="d-flex justify-content-between align-items-center">
            <span className="font-weight-bold">Total Hours</span>
            <div className="width-150">
              <div readOnly className="form-control border text-right m-1">
                {utils.formatHour(totalHours)}
              </div>
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
      {editOptionsModal ? (
        <QuoteItemEditOptionsModal
          title="Labor Categories"
          field="quoteItemLaborCategories"
          idField="laborCategoryId"
          quoteItem={quoteItemContext.quoteItem}
          onSubmit={() => {
            setEditOptionsModal(false);
            setQuoteItemContext({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => setEditOptionsModal(false)}
        />
      ) : null}
    </>
  );
};

export default QuoteItemLaborCategories;
