import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Row, Form, Col, Badge } from "reactstrap";
import InformationModal from "../../../components/InformationModal";
import { ACTIONS, useJobDetails } from "../../../providers/jobDetailsProvider";
import { jobsApi } from "../../../services/jobServices";
import ConfirmationModal from "../../ConfirmationModal";
import Loader from "../../Loader";
import ConvertToJobForm from "./ConvertToJobForm";
import JobForm from "./JobForm";

const STATUS_RFQ = 1;
const STATUS_PENDING = 3;
const STATUS_IN_PROGRESS = 4;
const STATUS_CANCELLED = 8;
const RFQ = "rfq";

const ProjectDetailsTab = () => {
  const [jobDetails, setJobDetails] = useJobDetails();

  const [jobDetailsLoading, setJobDetailsLoading] = useState();
  const [projectDetailsLoading, setProjectDetailsLoading] = useState();
  const [job, setJob] = useState({});
  const isJobLocked = jobDetails.job.lockDate;

  const location = useLocation();

  useEffect(() => {
    setJob(jobDetails.job);
  }, [jobDetails.job]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const setJobToCanceled = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        await jobsApi.updateJob({
          ...jobDetails.job,
          statusId: STATUS_CANCELLED,
        });
        setJobDetails({
          action: ACTIONS.REFRESH,
        });
        setInformationModal({
          isOpen: true,
          title: "Cancel Job",
          body: "Job cancelled successfully.",
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Cancel Job",
      body: `
            <div class="text-center">
                Do you confirm you want to cancel this job?
            </div>
      `,
      confirmColor: "danger",
    });
  };

  const onSubmitJobDetails = async (e) => {
    e.preventDefault();
    if (!job.managerId) {
      return setInformationModal({
        isOpen: true,
        title: `${job.id ? "Update" : "Create"} Job`,
        body: "Please select a manager.",
      });
    }
    setJobDetailsLoading(true);
    const data = !location.pathname.includes(RFQ)
      ? {
          ...job,
          contractDate:
            moment(job.contractDate).format("YYYY-MM-DD") ||
            moment().format("YYYY-MM-DD"),
          estimatedShipDate:
            moment(job.estimatedShipDate).format("YYYY-MM-DD") ||
            moment().format("YYYY-MM-DD"),
          shippedCompleteDate: job.shippedCompleteDate
            ? moment(job.shippedCompleteDate).format("YYYY-MM-DD")
            : null,
        }
      : {
          ...job,
          contractDate:
            moment(job.contractDate).format("YYYY-MM-DD") ||
            moment().format("YYYY-MM-DD"),
          estimatedShipDate:
            moment(job.estimatedShipDate).format("YYYY-MM-DD") ||
            moment().format("YYYY-MM-DD"),
        };
    try {
      setJobDetailsLoading(true);
      await jobsApi.updateJob(data);
      setJobDetailsLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Update Job",
        body: "Job Updated Successfully",
        onClose: () => {
          setInformationModal({ isOpen: false });
          setJobDetails({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setJobDetailsLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Update Job",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const onSubmitProjectDetails = async (e) => {
    e.preventDefault();
    if (!job.receivedById) {
      return setInformationModal({
        isOpen: true,
        title: `${job.id ? "Update" : "Create"} Job`,
        body: "Please select received by.",
      });
    }
    if (!job.estimatorId) {
      return setInformationModal({
        isOpen: true,
        title: `${job.id ? "Update" : "Create"} Job`,
        body: "Please select assigned estimator.",
      });
    }
    if (!job.customerId) {
      return setInformationModal({
        isOpen: true,
        title: `${job.id ? "Update" : "Create"} Job`,
        body: "Please select customer.",
      });
    }
    if (!job.customerContactId) {
      return setInformationModal({
        isOpen: true,
        title: "Create Job",
        body: "Please select a customer contact.",
      });
    }
    setProjectDetailsLoading(true);
    const data = {
      ...job,
      receivedDate:
        moment(job.receivedDate).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
      quoteDue:
        moment(job.quoteDue).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
    };
    try {
      setProjectDetailsLoading(true);
      await jobsApi.updateJob(data);
      setProjectDetailsLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Update Job",
        body: "Job Updated Successfully",
        onClose: () => {
          setInformationModal({ isOpen: false });
          setJobDetails({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setProjectDetailsLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Update Job",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  return (
    <Col>
      {jobDetails.job.statusId !== STATUS_RFQ &&
      jobDetails.job.statusId !== STATUS_PENDING ? (
        <Form
          onSubmit={onSubmitJobDetails}
          className="mt-3 col-12 d-flex flex-column"
        >
          <div className="d-flex align-items-start justify-content-between">
            <h3>Job Details</h3>
            {jobDetailsLoading ? (
              <Loader size="sm" align="end" />
            ) : !isJobLocked ? (
              <Button className="rounded" size="sm">
                Save
              </Button>
            ) : (
              <Badge
                className="px-1 py-0 d-flex align-items-center"
                color="danger"
                style={{ height: 25 }}
              >
                Locked
              </Badge>
            )}
          </div>
          <Row className="mt-2">
            <ConvertToJobForm job={job} setJob={setJob} />
          </Row>
        </Form>
      ) : null}
      <Form
        onSubmit={onSubmitProjectDetails}
        className="mt-4 col-12 d-flex flex-column"
      >
        <div className="d-flex align-items-start justify-content-between">
          <h3>Project Details</h3>
          {projectDetailsLoading ? (
            <Loader size="sm" align="end" />
          ) : !isJobLocked ? (
            <Button className="rounded" size="sm">
              Save
            </Button>
          ) : null}
        </div>
        <Row className="mt-2">
          <JobForm job={job} setJob={setJob} extended={true} />
          {job.statusId === STATUS_IN_PROGRESS ? (
            !isJobLocked ? (
              <Button
                size="sm"
                className="rounded ml-4 mt-3"
                color="danger"
                onClick={() => setJobToCanceled()}
              >
                Cancel Job
              </Button>
            ) : null
          ) : null}
        </Row>
      </Form>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Col>
  );
};

export default ProjectDetailsTab;
