import React, { useEffect, useState } from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import "react-day-picker/dist/style.css";

import { Col, CustomInput, FormGroup, Input, Label, Row } from "reactstrap";

import { usersApi } from "../../../services/userServices";
import { customersApi } from "../../../services/customerServices";
import { DayPicker } from "react-day-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { commonApi } from "../../../services/commonServices";
import { utils } from "../../../utils/utils";
import { useAuth } from "../../../providers/authProvider";

const PAGE_SIZE = 1000;

const RECEIVED_DATE = "receivedDate";
const QUOTE_DUE = "quoteDue";

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const animatedComponents = makeAnimated();

const JobForm = ({ job, setJob, extended }) => {
  const [authContext] = useAuth();

  //received date
  const [dateSelectorOpen, setDateSelectorOpen] = useState({});

  const isJobLocked = job.lockDate;

  //common data
  const [productTypes, setProductTypes] = useState([]);
  const [finishes, setFinishes] = useState([]);
  const [opBys, setOpBys] = useState([]);
  const [markings, setMarkings] = useState([]);
  const [freightCosts, setFreightCosts] = useState([]);
  const [bundlings, setBundlings] = useState([]);
  const [shipVias, setShipVias] = useState([]);

  //received by
  const [loadingReceivedBySearch, setLoadingReceivedBySearch] = useState();
  const [receivedByUsers, setReceivedByUsers] = useState([]);
  const [receivedBySearch, setReceivedBySearch] = useState();
  const [receivedBy, setReceivedBy] = useState();

  //assigned estimator
  const [loadingEstimatorSearch, setLoadingEstimatorSearch] = useState();
  const [estimatorUsers, setEstimatorUsers] = useState([]);
  const [estimatorSearch, setEstimatorSearch] = useState();
  const [estimator, setEstimator] = useState();

  //customer
  const [loadingCustomerSearch, setLoadingCustomerSearch] = useState();
  const [customers, setCustomers] = useState([]);
  const [customerSearch, setCustomerSearch] = useState();
  const [customer, setCustomer] = useState();

  useEffect(() => {
    const { receivedByUser, estimatorUser, customerId } = job;
    if (!receivedBy && receivedByUser) {
      setReceivedBy({
        label: ` ${receivedByUser.firstName} ${receivedByUser.lastName}`,
        value: receivedByUser.id,
      });
    }

    if (customerId && customers.length) {
      const selectedCustomer = customers.find((c) => c.id === customerId);
      if (selectedCustomer?.name && selectedCustomer?.id) {
        setCustomer({
          label: ` ${selectedCustomer.name}`,
          value: selectedCustomer.id,
          contacts: selectedCustomer.contacts,
        });
      }
    }

    if (!estimator && estimatorUser) {
      setEstimator({
        label: ` ${estimatorUser.firstName} ${estimatorUser.lastName}`,
        value: estimatorUser.id,
      });
    }
  }, [estimator, receivedBy, customers, job]);

  // Fetch common data
  useEffect(() => {
    const fetchData = async () => {
      const productTypes = await commonApi.getProductTypes();
      const finishes = await commonApi.getFinishes();
      const opBys = await commonApi.getOpBys();
      const markings = await commonApi.getMarkings();
      const freightCosts = await commonApi.getFreightCost();
      const bundlings = await commonApi.getBundlings();
      const shipVias = await commonApi.getShipVias();
      setProductTypes(productTypes);
      setFinishes(finishes);
      setOpBys(opBys);
      setMarkings(markings);
      setFreightCosts(freightCosts);
      setBundlings(bundlings);
      setShipVias(shipVias);
    };
    if (extended) {
      fetchData();
    }
  }, [extended]);

  // Search assigned estimator users
  useEffect(() => {
    const fetchData = async () => {
      setLoadingEstimatorSearch(true);
      const results = await usersApi.getUsers({
        search: estimatorSearch || "",
        pageSize: PAGE_SIZE,
      });
      setEstimatorUsers(results?.data || []);
      setLoadingEstimatorSearch(false);
    };
    fetchData();
  }, [estimatorSearch]);

  // Search received by users
  useEffect(() => {
    const fetchData = async () => {
      setLoadingReceivedBySearch(true);
      const results = await usersApi.getUsers({
        search: receivedBySearch || "",
        pageSize: PAGE_SIZE,
      });
      setReceivedByUsers(results?.data || []);
      setLoadingReceivedBySearch(false);
    };
    fetchData();
  }, [receivedBySearch]);

  // Search customers
  useEffect(() => {
    const fetchData = async () => {
      setLoadingCustomerSearch(true);
      const results = await customersApi.getCustomers({
        search: customerSearch || "",
        pageSize: PAGE_SIZE,
      });
      setCustomers(results?.data || []);
      setLoadingCustomerSearch(false);
    };
    fetchData();
  }, [customerSearch]);

  return (
    <Row className="col-12">
      <Col className={extended ? "col-6" : "col-12"}>
        <div className="d-flex">
          <Col className="col-6">
            <FormGroup className="d-flex flex-column">
              <Label for={RECEIVED_DATE}>
                <span>Received Date</span>
                <span className="text-danger ml-2">*</span>
              </Label>
              {dateSelectorOpen[RECEIVED_DATE] ? (
                <DayPicker
                  className="date-picker bg-white rounded border d-flex justify-content-center m-0"
                  mode="single"
                  defaultMonth={
                    job.receivedDate ? new Date(job.receivedDate) : new Date()
                  }
                  selected={
                    job.receivedDate
                      ? utils.dateForDayPicker(job.receivedDate)
                      : new Date()
                  }
                  modifiersClassNames={{
                    selected: "my-selected",
                    today: "my-today",
                    range_start: "my-range_start",
                    range_end: "my-range_end",
                  }}
                  onDayClick={(receivedDate) => {
                    setDateSelectorOpen({ [RECEIVED_DATE]: false });
                    setJob({
                      ...job,
                      receivedDate,
                    });
                  }}
                />
              ) : (
                <div
                  className={`col-12 z-index-0 p-2 border rounded bg-white ${
                    isJobLocked ? "form-control" : "cursor-pointer"
                  }`}
                  onClick={() =>
                    isJobLocked ||
                    setDateSelectorOpen({ [RECEIVED_DATE]: true })
                  }
                  readOnly={isJobLocked}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={faCalendar}
                    className="z-index-0 text-secondary mr-2"
                  />
                  <span>
                    {job.receivedDate
                      ? utils.formatDate(job.receivedDate)
                      : "Select a Date ..."}
                  </span>
                </div>
              )}
            </FormGroup>
          </Col>
          <Col className="col-6">
            <FormGroup className="d-flex flex-column">
              <Label for={QUOTE_DUE}>
                <span>Quote Due</span>
                <span className="text-danger ml-2">*</span>
              </Label>
              {dateSelectorOpen[QUOTE_DUE] ? (
                <DayPicker
                  className="date-picker bg-white rounded border d-flex justify-content-center m-0"
                  mode="single"
                  modifiersClassNames={{
                    selected: "my-selected",
                    today: "my-today",
                    range_start: "my-range_start",
                    range_end: "my-range_end",
                  }}
                  defaultMonth={
                    job.quoteDue ? new Date(job.quoteDue) : new Date()
                  }
                  selected={
                    job.quoteDue
                      ? utils.dateForDayPicker(job.quoteDue)
                      : new Date()
                  }
                  onDayClick={(quoteDue) => {
                    setDateSelectorOpen({ [QUOTE_DUE]: false });
                    setJob({
                      ...job,
                      quoteDue,
                    });
                  }}
                />
              ) : (
                <div
                  className={`col-12 z-index-0 p-2 border rounded bg-white ${
                    isJobLocked ? "form-control" : "cursor-pointer"
                  }`}
                  onClick={() =>
                    isJobLocked || setDateSelectorOpen({ [QUOTE_DUE]: true })
                  }
                  readOnly={isJobLocked}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={faCalendar}
                    className="z-index-0 text-secondary mr-2"
                  />
                  <span>
                    {job.quoteDue
                      ? utils.formatDate(job.quoteDue)
                      : "Select a Date ..."}
                  </span>
                </div>
              )}
            </FormGroup>
          </Col>
        </div>
        <Col className="col-12">
          <FormGroup>
            <Label for="receivedBy">
              <span>Received By</span>
              <span className="text-danger ml-2">*</span>
            </Label>
            {isJobLocked ? (
              <Input
                className="height-40p border"
                readOnly={true}
                type="text"
                name="receivedBy"
                defaultValue={receivedBy?.label}
              />
            ) : (
              <Select
                id="receivedBy"
                placeholder={
                  <span className="text-muted">Search for an user...</span>
                }
                noOptionsMessage={() => "No users found"}
                styles={style}
                className="flex-grow-1 border rounded"
                options={receivedByUsers?.map((c) => ({
                  label: `${c.firstName} ${c.lastName}`,
                  value: c.id,
                }))}
                closeMenuOnSelect={true}
                components={animatedComponents}
                isSearchable
                isClearable
                isMulti={false}
                inputValue={receivedBySearch}
                value={receivedBy}
                onInputChange={setReceivedBySearch}
                isLoading={loadingReceivedBySearch}
                onChange={(value) => {
                  setReceivedBy(value);
                  setJob({
                    ...job,
                    receivedById: value?.value,
                  });
                }}
              />
            )}
          </FormGroup>
        </Col>
        <Col className="col-12">
          <FormGroup>
            <Label for="customer">
              <span>Customer</span>
              <span className="text-danger ml-2">*</span>
            </Label>
            {isJobLocked ? (
              <Input
                className="height-40p border"
                readOnly={true}
                type="text"
                name="customer"
                defaultValue={customer?.label}
              />
            ) : (
              <Select
                id="customer"
                placeholder={
                  <span className="text-muted">Search for a customer...</span>
                }
                noOptionsMessage={() => "No customers found"}
                styles={style}
                className="flex-grow-1 border rounded"
                options={customers?.map((c) => ({
                  label: `${c.name}`,
                  value: c.id,
                }))}
                isDisabled={
                  !utils.isAdmin(authContext.currentUser.role.id) && job.id
                }
                closeMenuOnSelect={true}
                components={animatedComponents}
                isSearchable
                isClearable
                isMulti={false}
                inputValue={customerSearch}
                value={customer}
                onInputChange={setCustomerSearch}
                isLoading={loadingCustomerSearch}
                onChange={(value) => {
                  if (value) {
                    const contacts =
                      customers.find((c) => c.id === value.value)?.contacts ||
                      [];
                    setCustomer({
                      ...value,
                      contacts,
                    });
                    setJob({
                      ...job,
                      customerId: value.value,
                      customerContactId: null,
                    });
                  }
                }}
              />
            )}
          </FormGroup>
        </Col>
        {customer?.contacts ? (
          <Col className="col-12">
            <FormGroup>
              <Label for="customer">Customer Contact</Label>
              <CustomInput
                className="height-40p border form-control"
                readOnly={isJobLocked}
                id="cContactSelect"
                type="select"
                disabled={
                  isJobLocked ||
                  (!utils.isAdmin(authContext.currentUser.role.id) && job.id)
                }
                name="cContactSelect"
                value={job.customerContactId || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    customerContactId: e.target.value,
                  })
                }
              >
                <option value={""}>Select a contact..</option>
                {customer.contacts?.map((contact) => (
                  <option key={contact.id} value={contact.id}>
                    {contact.firstName} {contact.lastName}
                  </option>
                ))}
              </CustomInput>
            </FormGroup>
          </Col>
        ) : null}
        <Col className="col-12">
          <FormGroup>
            <Label for="customerRFQNumber">Customer RFQ#</Label>
            <Input
              className="height-40p border"
              placeholder={
                isJobLocked ? "No Customer RFQ#" : "Enter RFQ Number..."
              }
              maxLength="50"
              type="text"
              readOnly={isJobLocked}
              name="customerRFQNumber"
              value={job.customerRFQNumber || ""}
              onChange={(e) =>
                setJob({
                  ...job,
                  customerRFQNumber: e.target.value,
                })
              }
            />
          </FormGroup>
        </Col>
        <Col className="col-12">
          <FormGroup>
            <Label for="description">
              <span>Description</span>
              <span className="text-danger ml-2">*</span>
            </Label>
            <Input
              required
              className="height-40p border"
              placeholder="Enter Description..."
              maxLength="50"
              type="text"
              readOnly={isJobLocked}
              name="description"
              value={job.description || ""}
              onChange={(e) =>
                setJob({
                  ...job,
                  description: e.target.value,
                })
              }
            />
          </FormGroup>
        </Col>
        <Col className="col-12">
          <FormGroup>
            <Label for="location">
              <span>Location</span>
              <span className="text-danger ml-2">*</span>
            </Label>
            <Input
              required
              className="height-40p border"
              placeholder="Enter Location..."
              maxLength="50"
              type="text"
              readOnly={isJobLocked}
              name="location"
              value={job.location || ""}
              onChange={(e) =>
                setJob({
                  ...job,
                  location: e.target.value,
                })
              }
            />
          </FormGroup>
        </Col>
        <Col className="col-12">
          <FormGroup>
            <Label for="Estimator">
              <span>Estimator</span>
              <span className="text-danger ml-2">*</span>
            </Label>
            {isJobLocked ? (
              <Input
                className="height-40p border"
                readOnly={true}
                type="text"
                name="Estimator"
                defaultValue={estimator?.label}
              />
            ) : (
              <Select
                id="Estimator"
                placeholder={
                  <span className="text-muted">Search for an user...</span>
                }
                noOptionsMessage={() => "No users found"}
                styles={style}
                className="flex-grow-1 border rounded"
                options={estimatorUsers?.map((c) => ({
                  label: `${c.firstName} ${c.lastName}`,
                  value: c.id,
                }))}
                closeMenuOnSelect={true}
                components={animatedComponents}
                isSearchable
                isClearable
                isMulti={false}
                inputValue={estimatorSearch}
                value={estimator}
                onInputChange={setEstimatorSearch}
                isLoading={loadingEstimatorSearch}
                onChange={(value) => {
                  if (value) {
                    setEstimator(value);
                    setJob({
                      ...job,
                      estimatorId: value.value,
                    });
                  }
                }}
              />
            )}
          </FormGroup>
        </Col>
        <Col className="col-12">
          <FormGroup>
            <Label for="SQNotes">
              <span>SQ Notes</span>
            </Label>
            <Input
              name="SQNotes"
              placeholder="SQ Notes..."
              maxLength="4096"
              type="textarea"
              rows="7"
              value={job.sqNotes || ""}
              onChange={(e) => {
                setJob({
                  ...job,
                  sqNotes: e.target.value,
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col className="col-12">
          <FormGroup className="mb-0">
            <Label for="Notes">
              <span>Notes</span>
            </Label>
            <Input
              name="Notes"
              placeholder="Notes..."
              maxLength="4096"
              type="textarea"
              rows="7"
              value={job.notes || ""}
              onChange={(e) => {
                setJob({
                  ...job,
                  notes: e.target.value,
                });
              }}
            />
          </FormGroup>
        </Col>
      </Col>
      {extended ? (
        <Col className={extended ? "col-6" : "col-12"}>
          <Col className="col-12">
            <FormGroup>
              <Label for="productTypeId">Product Type</Label>
              <CustomInput
                className="height-40p border form-control"
                readOnly={isJobLocked}
                disabled={isJobLocked}
                id="pTypeSelect"
                type="select"
                name="pTypeSelect"
                value={job.productTypeId || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    productTypeId: e.target.value || null,
                  })
                }
              >
                <option value={""}>Select a product type..</option>
                {productTypes?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </CustomInput>
            </FormGroup>
          </Col>
          <Col className="col-12">
            <FormGroup>
              <Label for="finishId">Finish</Label>
              <CustomInput
                className="height-40p border form-control"
                readOnly={isJobLocked}
                disabled={isJobLocked}
                id="finishSelect"
                type="select"
                name="finishSelect"
                value={job.finishId || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    finishId: e.target.value || null,
                  })
                }
              >
                <option value={""}>Select a finish..</option>
                {finishes?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </CustomInput>
            </FormGroup>
          </Col>
          <Col className="col-12">
            <FormGroup>
              <Label for="opById">OpBy</Label>
              <CustomInput
                className="height-40p border form-control"
                readOnly={isJobLocked}
                disabled={isJobLocked}
                id="opBySelect"
                type="select"
                name="opBySelect"
                value={job.opById || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    opById: e.target.value || null,
                  })
                }
              >
                <option value={""}>Select a opBy..</option>
                {opBys?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </CustomInput>
            </FormGroup>
          </Col>
          <Col className="col-12">
            <FormGroup>
              <Label for="markingId">Marking</Label>
              <CustomInput
                className="height-40p border form-control"
                readOnly={isJobLocked}
                disabled={isJobLocked}
                id="markingSelect"
                type="select"
                name="markingSelect"
                value={job.markingId || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    markingId: e.target.value || null,
                  })
                }
              >
                <option value={""}>Select a marking..</option>
                {markings?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </CustomInput>
            </FormGroup>
          </Col>
          <Col className="col-12">
            <FormGroup>
              <Label for="bundlingId">Bundling</Label>
              <CustomInput
                className="height-40p border form-control"
                readOnly={isJobLocked}
                disabled={isJobLocked}
                id="bundlingSelect"
                type="select"
                name="bundlingSelect"
                value={job.bundlingId || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    bundlingId: e.target.value || null,
                  })
                }
              >
                <option value={""}>Select a bundling..</option>
                {bundlings?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </CustomInput>
            </FormGroup>
          </Col>
          <Col className="col-12">
            <FormGroup>
              <Label for="shipVia">Ship Via</Label>
              <CustomInput
                className="height-40p border form-control"
                readOnly={isJobLocked}
                disabled={isJobLocked}
                id="shipViaSelect"
                type="select"
                name="shipViaSelect"
                value={job.shipViaId || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    shipViaId: e.target.value || null,
                  })
                }
              >
                <option value={""}>Select a ship via..</option>
                {shipVias?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </CustomInput>
            </FormGroup>
          </Col>
          <Col className="col-12">
            <FormGroup>
              <Label for="freightCostId">Freight Cost</Label>
              <CustomInput
                className="height-40p border form-control"
                readOnly={isJobLocked}
                disabled={isJobLocked}
                id="freightCostSelect"
                type="select"
                name="freightCostSelect"
                value={job.freightCostId || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    freightCostId: e.target.value || null,
                  })
                }
              >
                <option value={""}>Select a freight Cost ..</option>
                {freightCosts?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </CustomInput>
            </FormGroup>
          </Col>
        </Col>
      ) : null}
    </Row>
  );
};

export default JobForm;
